import _deferred from "./lib/deferred";
import _once from "./lib/once";
import _promiseOrCallback from "./lib/promise-or-callback";
var exports = {};
var deferred_1 = _deferred;
var once_1 = _once;
var promise_or_callback_1 = _promiseOrCallback;
function wrapPromise(fn) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    var callback;
    var lastArg = args[args.length - 1];
    if (typeof lastArg === "function") {
      callback = args.pop();
      callback = (0, once_1.once)((0, deferred_1.deferred)(callback));
    }
    // I know, I know, this looks bad. But it's a quirk of the library that
    // we need to allow passing the this context to the original function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: this has an implicit any
    return (0, promise_or_callback_1.promiseOrCallback)(fn.apply(this, args), callback); // eslint-disable-line no-invalid-this
  };
}
wrapPromise.wrapPrototype = function (target, options) {
  if (options === void 0) {
    options = {};
  }
  var ignoreMethods = options.ignoreMethods || [];
  var includePrivateMethods = options.transformPrivateMethods === true;
  var methods = Object.getOwnPropertyNames(target.prototype).filter(function (method) {
    var isNotPrivateMethod;
    var isNonConstructorFunction = method !== "constructor" && typeof target.prototype[method] === "function";
    var isNotAnIgnoredMethod = ignoreMethods.indexOf(method) === -1;
    if (includePrivateMethods) {
      isNotPrivateMethod = true;
    } else {
      isNotPrivateMethod = method.charAt(0) !== "_";
    }
    return isNonConstructorFunction && isNotPrivateMethod && isNotAnIgnoredMethod;
  });
  methods.forEach(function (method) {
    var original = target.prototype[method];
    target.prototype[method] = wrapPromise(original);
  });
  return target;
};
exports = wrapPromise;
export default exports;