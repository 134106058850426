var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.promiseOrCallback = void 0;
/* eslint-disable consistent-return */
function promiseOrCallback(promise, callback) {
  if (!callback) {
    return promise;
  }
  promise.then(function (data) {
    return callback(null, data);
  }).catch(function (err) {
    return callback(err);
  });
}
exports.promiseOrCallback = promiseOrCallback;
export default exports;