var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deferred = void 0;
function deferred(fn) {
  return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    setTimeout(function () {
      try {
        fn.apply(void 0, args);
      } catch (err) {
        /* eslint-disable no-console */
        console.log("Error in callback function");
        console.log(err);
        /* eslint-enable no-console */
      }
    }, 1);
  };
}
exports.deferred = deferred;
export default exports;